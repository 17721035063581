* {
    list-style-type: none;
}

a {
    text-decoration: none;
    /* color: rgb(58, 141, 219); */
}

.center-1 {
    margin: 5px;
}

.center-2 {
    margin: 5px;
    padding: 0 8px 0 8px;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.center-3 {
    margin: 5px;
    color: gray;
}

.create-spot-text {
    color: black;
    padding: 12px;
    border-radius: 20px;
}

.create-spot-text:hover {
    background: rgb(241, 241, 241);
    padding: 12px;
    border-radius: 20px;
}

.fa-bars {
    margin: 1px;
    opacity: 0.8;
    font-size: 14px;
}

.fa-compass {
    margin: 1px;
    opacity: 0.6;
    font-size: 24px;
}

.fa-book-atlas {
    color: rgb(245, 17, 85);
    font-size: 30px;
}

.fa-globe {
    opacity: 0.7;
    font-size: 20px;
    margin: 0 6px 0 2px;
    padding: 9px;
    border-radius: 20px;
}

.fa-globe:hover {
    cursor: pointer;
    background: rgb(236, 235, 235);
}

.fa-magnifying-glass {
    font-size: 12px;
    background: rgb(245, 17, 85);
    color: white;
    padding: 8px;
    border-radius: 15px;
    margin-left: 5px;
}

.hidden {
    display: none;
}

.nav-logo {
    color: rgb(245, 17, 85);
    font-size: 20px;
    text-align: center;
}

.nav-menu-center {
    display: flex;
    padding: 9px;
    border: 1px solid lightgray;
    border-radius: 25px;
    box-shadow: lightgray 0px 0.5px 0.5px 0.5px;
}

.nav-menu-center:hover {
    cursor: pointer;
    box-shadow: lightgray 0px 1px 1px 1px;
}

.nav-right-menu {
    align-items: center;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 25px;
}

.nav-right-menu:hover {
    cursor: pointer;
    box-shadow: lightgray 0px 1px 1px 1px;
}

.nav-wrapper {
    height: 4rem;
    border-bottom: solid 1px grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0 3rem 0 1.5rem;
    font-size: 15px;
    font-weight: 500;
}

.nav-wrapper-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-button-link:hover {
    cursor: pointer;
}

.profile-dropdown {
    text-align: center;
    position: absolute;
    top: 4rem;
    right: 3rem;
    /* width: 10rem; */
    color: black;
    font-size: 16px;
    padding: 2px;
    background-color: white;
    opacity: 1;
    border: 2px solid black;
    z-index: 10;
}

.profile-logout-button {
    height: 2rem;
    border-radius: 0.7rem;
    border: 1px solid black;
    color: white;
    background: rgb(80, 76, 76);
}

.profile-logout-button:hover {
    cursor: pointer;
}

.profile-manage-spots-link {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 0.5rem;
    margin: 0.5rem;
    color: black;
}

.profile-manage-spots-link-a {
    color: black;
}

.profile-menu {
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: none;
}

.profile-menu:hover {
    cursor: pointer;
}

.settings-button {
    padding: 0;
    background: none;
    border: none;
}
