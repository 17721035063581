.preview-image {
    width: 100%;
    height: 500px;
}


.hide-this-janky-variable {
    display: none;
}

.image1 {
    grid-area: image1;
}

.image2 {
    grid-area: image2;
}

.image3 {
    grid-area: image3;
}

.image4 {
    grid-area: image4;
}

.image5 {
    grid-area: image5;
}

.new-star-bottom {
    margin-bottom: 6px;
}

.post-review {
    background-color: rgb(95, 90, 90);
    color: white;
    box-shadow: 3px 2px black;
}

.post-review:hover {
    cursor: pointer;
}

.post-review-top {
    position: absolute;
    top: 59rem;
}

.reserve-box {
    width: 35%;
    height: 5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 2px solid black;
    border-radius: 10px;
}

.reserve-box-rating {
    /* margin-left: 20%; */
}

.reserve-button {
    background: lightcoral;
    color: white;
    height: 4rem;
    border: 2px solid black;
    box-shadow: 2px 3px black;
}

.reserve-top {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.review-date {
    color: gray;
}

.review-info {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.reviews-heading {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 2rem;
}

.single-review {
    margin-bottom: 3rem;
}

.single-spot-image {
    width: 100%;
    height: 245px;
}

.spot-host {
    font-size: 24px;
}

.spot-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
    grid-template-areas:
        "image1 image1 image2 image3"
        "image1 image1 image4 image5"
        ". . . ."
        ". . . .";
}

.spot-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 2px solid black;
}

.spot-name {
    font-size: 24px;
}

.spot-text {
    width: 65%;
}

.spot-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem 3rem;
}
